import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/common/IconButton';
import {
  Col,
  Row,
  Form,
  FormControl,
  InputGroup,
  Spinner,
  Alert,
  Button
} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import BotSummary from 'pages/CreateBot/BotSummary';
import InfoPopover from 'components/utilities/InfoPopover';
import 'assets/scss/custom/wallet.scss';
import { Wallet as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { cleanCache } from 'services/cache';
import { teamLevel } from 'staticData/common';
import Avatar from 'components/common/Avatar';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { breakpoints, getColor } from 'helpers/utils';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { botGeneralInfo } from 'staticData/common';
import SoftBadge from 'components/common/SoftBadge';
import { portfolioList } from 'pages/CreateBot/staticData/portfolioList';
import { currencyMap, coinsData } from 'services/coins/common';
import classNames from 'classnames';
import PricingModal from 'pages/Pricing/modals';
import { saveToLocalStorage } from 'staticData/common';
import SimpleBarReact from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackEvent } from 'services/externals/mixpanel';
import LottieBotLazy from 'services/lazyLoading/LottieBotLazy';
import { walletImgRender } from 'services/coins/components';
import NoWalletConnected from './NoWalletConnected';

const PortfolioWallet = ({
  id,
  setCreatedBot,
  maxFirstProTierTotalAllocation
}) => {
  const {
    config: { lang, currency },
    userInfos
  } = useContext(AppContext);
  // can get exchange from query parameter
  const queryParameters = new URLSearchParams(window.location.search);
  const exchange = queryParameters.get('exchange') || 'binance';
  const now = new Date();
  // use to check if is in virtual trial mode
  const isInVirtualTrial =
    exchange === 'virtual' && new Date(userInfos.virtual_trial?.end) > now;

  const {
    id: botID,
    name,
    coins,
    quote,
    riskLevels,
    backendMinLevels,
    bgStyle
  } = portfolioList.find(bot => String(bot.id) === String(id));
  const riskUrl = queryParameters.get('risk') || riskLevels[0];
  let riskIndex = riskLevels.indexOf(Number(riskUrl));
  const backendRisk = backendMinLevels[riskIndex > -1 ? riskIndex : 0];
  const type = Number(id);

  const [loaded, setLoaded] = useState(false);
  const [aBotIsPending, setaBotIsPending] = useState(false);
  const [botParams, setBotParams] = useState({});
  const [runBotButtonDisabled, setRunBotButtonDisabled] = useState(false);
  const [botList, setBotList] = useState([]);
  const [checkedBots, setCheckedBots] = useState([]);
  const [canModify, setCanModify] = useState(false);
  const [disableModify, setDisableModify] = useState(false);
  const [acceptedTos, setAcceptedTos] = useState(false);
  const [showPortfolioError, setShowPortfolioError] = useState(false);
  const [portfolioError, setPortfolioError] = useState('');
  const [minCoinsError, setMinCoinsError] = useState('');
  const [orderedCoins, setOrderedCoins] = useState([]);
  const [showModalPricing, setShowModalPricing] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [maxValue, setMaxValue] = useState(0);
  const [canStartTrial, setCanStartTrial] = useState(false);
  const [minCap, setMinCap] = useState(0);
  const [maxCap, setMaxCap] = useState(maxFirstProTierTotalAllocation);
  const routerHistory = useHistory();
  const chartRef = useRef(null);
  const coinToConvertMinValue = 5;

  const goBack = () => {
    queryParameters.delete('wallet');
    routerHistory.push(`?${queryParameters.toString()}`);
  };

  const goVirtual = () => {
    queryParameters.set('exchange', 'virtual');
    routerHistory.push(`?${queryParameters.toString()}`);
  };

  // const handleBotClick = botID => {
  //   setCheckedBots(prevCheckedBots =>
  //     prevCheckedBots.includes(botID)
  //       ? prevCheckedBots.filter(id => id !== botID)
  //       : [...prevCheckedBots, botID]
  //   );
  // };

  const getLimits = async () => {
    let resp = await endpointInterfaceV2({
      internalEndpoint: 'createBotLimits',
      httpMethod: 'post',
      usingCredentials: true,
      bodyParams: {
        assets: coins,
        exchange: 'binance',
        quote: quote,
        type: botGeneralInfo[type].id,
        virtual: exchange === 'virtual'
      }
    });
    var data = {};
    if (resp.validResponse) {
      data = resp.data;
      if (!data.allowed) setPortfolioError(data.reason);
    }
    return data;
  };

  const getBotData = async () => {
    var params = { virtual: false };
    let walletStatsResponse = await endpointInterface(
      lang,
      'backend',
      'getBots',
      'get',
      true,
      params
    );
    if (walletStatsResponse.validResponse) {
      const hasPendingStatus = walletStatsResponse.data.some(
        item => item.status === 'PENDING'
      );
      if (hasPendingStatus) setaBotIsPending(true);

      const onlyBotList = walletStatsResponse.data.filter(
        item => item.category !== 'portfolio'
      );
      setBotList(onlyBotList);
      if (onlyBotList.length > 0) {
        const allBotIDs = onlyBotList.map(bot => bot.id);
        setCheckedBots(allBotIDs);
      }
    } else {
      toast.error(walletStatsResponse.responseMessage, { closeButton: false });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
      return {};
    }
  };

  const portfolioQuantityCheck = () => {
    return (
      !canStartTrial &&
      userInfos.level < teamLevel &&
      ((exchange === 'virtual' && userInfos.virtual_portfolio_active) ||
        (((userInfos.real_portfolio_active &&
          !userInfos.permissions.max_real_portfolio_qty) ||
          userInfos.real_portfolio_count >=
            userInfos.permissions.max_real_portfolio_qty) &&
          exchange === 'binance'))
    );
  };

  const checkCoins = localWallet => {
    let invalidCoins = [];
    for (let key in localWallet) {
      if (!coins.includes(key)) {
        let amount = localWallet[key];
        let price = liquidityData[key]?.price;
        if (price) {
          let totalValue = amount * price;
          if (totalValue < coinToConvertMinValue) {
            invalidCoins.push(key);
          }
        }
      }
    }
    if (invalidCoins.length > 0)
      setMinCoinsError(invalidCoins.join(', ') || '');
    return invalidCoins.length > 0;
  };

  const createBot = async params => {
    setRunBotButtonDisabled(true);
    let error = true;
    if (portfolioQuantityCheck()) {
      if (!portfolioError) setPortfolioError('portfolio_number');
      setShowPortfolioError(true);
    } else if (checkCoins(params.wallet)) {
      if (!portfolioError) setPortfolioError('min_coins');
      setShowPortfolioError(true);
    } else {
      let resp = await endpointInterfaceV2({
        internalEndpoint: 'createBot',
        httpMethod: 'post',
        usingCredentials: true,
        bodyParams: params
      });
      if (resp.validResponse) {
        if (!params.virtual) {
          trackEvent('portfolio_started', {
            type: params.type,
            parameters: params
          });
        }
        if (resp.data.bot_task) setCreatedBot({ bot_task: resp.data.bot_task });
        else setCreatedBot(resp.data.bot_info);
        await cleanCache(0);
        error = false;
      }
    }
    setRunBotButtonDisabled(false);
    return error;
  };

  const adjustObjectForBackend = () => {
    let transformedData = {};

    // convert actual obj
    Object.keys(coinsAmount).forEach(key => {
      if (coinsAmount[key].quantity !== 0)
        transformedData[key] = coinsAmount[key].quantity;
    });

    return transformedData;
  };

  const runYourBot = async () => {
    let localWallet = adjustObjectForBackend();
    const localBotParams = {
      exchange: 'binance',
      quote: quote,
      type: botGeneralInfo[type].id,
      virtual: exchange === 'virtual',
      wallet: localWallet,
      assets: coins,
      selected_fee: 0,
      parameters: { min_level: backendRisk },
      bots_to_replace: checkedBots
    };
    setBotParams(localBotParams);
    // all checks for IF statement
    const categoryPermission =
      userInfos.permissions[
        `bot_type_${
          exchange === 'virtual' ? 'virtual' : 'real'
        }_allowed_categories`
      ].includes('portfolio');
    const botPermission =
      exchange === 'virtual'
        ? true
        : userInfos.permissions.bot_types.includes(
            Number(botGeneralInfo[type].id)
          );
    const hasCategoryAndBotPermission = categoryPermission && botPermission;
    const isVirtualExchange = exchange === 'virtual';
    const isUnderMaxCap = sumEquivalent(coinsAmount) <= maxCap;
    if (canStartTrial) {
      setShowModalPricing(true);
    } else if (
      isInVirtualTrial ||
      (hasCategoryAndBotPermission &&
        (isVirtualExchange || (!isVirtualExchange && isUnderMaxCap)))
    ) {
      await createBot(localBotParams);
    } else if (portfolioQuantityCheck()) {
      setPortfolioError('portfolio_number');
      setShowPortfolioError(true);
    } else {
      setShowModalPricing(true);
      saveToLocalStorage('savedBotParams', {
        ...localBotParams,
        time: new Date(),
        botCategory: 'portfolio'
      });
    }
  };

  const [coinsAmount, setCoinsAmount] = useState({});
  const [liquidityData, setLiquidityData] = useState();

  const handleNewAmount = (coin, amount, { min = 0, max } = {}) => {
    let newAmount = Number(amount);
    if (newAmount < min) {
      newAmount = min;
    } else if (max !== undefined && newAmount > max) {
      newAmount = max;
    }
    setCoinsAmount(prevAmounts => ({
      ...prevAmounts,
      [coin]: {
        max: coinsAmount[coin].max,
        quantity: amount === '' ? '' : newAmount,
        equivalent: newAmount * liquidityData[coin].price
      }
    }));
  };

  const sumEquivalent = obj => {
    // check if there is a bot pending
    if (aBotIsPending) return 0;

    // Reduce the obj to get the initial sum of equivalents
    const initialSum = Object.values(obj).reduce(
      (sum, { equivalent }) => sum + equivalent,
      0
    );

    // Calculate the sum of the equivalents for the bots selected in checkedBots
    const checkedBotsSum = checkedBots.reduce((sum, botId) => {
      // Find the corresponding bot in botList
      const bot = botList.find(bot => bot.id === botId);

      // If the bot is found and the currency is valid, add its equivalent value
      if (bot && bot.last_stat && bot.last_stat.eqv && currencyMap[currency]) {
        const equivalentValue = bot.last_stat.eqv[currencyMap[currency]];
        return sum + (equivalentValue || 0);
      }

      return sum;
    }, 0);

    // Return the total sum: initial sum + sum of checkedBots
    return initialSum + checkedBotsSum;
  };

  const getMax = () => {
    // Calculate the sum from liquidityData
    const liquiditySum = Object.values(liquidityData).reduce(
      (sum, { max, price }) => sum + max * price,
      0
    );

    // Calculate the sum from botList
    const botsSum = botList.reduce((sum, bot) => {
      // Check if the bot has max and price values
      if (bot.last_stat?.eqv[currencyMap[currency]]) {
        return sum + bot.last_stat.eqv[currencyMap[currency]];
      }
      return sum;
    }, 0);

    // Return the total sum from liquidityData and botList
    return liquiditySum + botsSum;
  };

  const getBotDataById = id => {
    for (const key in botGeneralInfo) {
      if (botGeneralInfo[key].id === id) {
        return { type: key, name: botGeneralInfo[key].name };
      }
    }
    return 'noBot';
  };

  const initMaxValues = data => {
    let transformedObject = {};
    // Transform the original data to calculate equivalent values
    for (const key in data) {
      if (Object.hasOwn(data, key)) {
        const { max, price } = data[key];
        transformedObject[key] = {
          max: max * price,
          quantity: max,
          equivalent: max * price
        };
      }
    }
    // Get the list of keys from the transformed object
    let keys = Object.keys(transformedObject);
    // Filter out keys where equivalent is 0 and then sort the remaining keys based on equivalent values in descending order
    keys = keys.sort(
      (a, b) =>
        transformedObject[b].equivalent - transformedObject[a].equivalent
    );
    return [transformedObject, keys];
  };

  let graphData = Object.keys(coinsAmount).map(coinKey => ({
    value: coinsAmount[coinKey]?.equivalent || 0,
    name: coinKey,
    itemStyle: {
      color: coinsData?.[coinKey]?.color || '#ffffff'
    }
  }));

  graphData.push({
    value: maxValue - sumEquivalent(coinsAmount),
    name: textLang.available[lang],
    itemStyle: {
      color: '#3f3f3ffd'
    }
  });

  const getGraphOption = () => {
    return {
      tooltip: {
        trigger: 'item',
        padding: [7, 10],
        backgroundColor: getColor('100'),
        borderColor: getColor('300'),
        textStyle: { color: getColor('dark') },
        borderWidth: 1,
        transitionDuration: 0,
        axisPointer: {
          type: 'none'
        },
        formatter: function (params) {
          return `<strong>${params.data.name}:</strong> ${params.percent}% (${
            parseFloat(params.data.value).toFixed(2) + ' $'
          })`;
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['50%', '90%'],
          avoidLabelOverlap: false,
          label: {
            show: false
          },
          data: graphData
        }
      ]
    };
  };

  const getVirtualTrial = async () => {
    let resp = await endpointInterfaceV2({
      internalEndpoint: 'virtual_trial',
      httpMethod: 'get',
      usingCredentials: true,
      showError: false
    });
    if (resp.validResponse) {
      if (resp.data.can_start_trial)
        setCanStartTrial(true && !userInfos.isInCluster);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        getVirtualTrial();
        // check if user can start real bot
        if (exchange === 'virtual' || userInfos.connected_exchanges.length) {
          if (exchange !== 'virtual') getBotData();
          const limits = await getLimits();
          setMinCap(limits.usd_equiv.min);
          if (userInfos.pro_tier > 0 && limits.usd_equiv.max >= 0)
            setMaxCap(limits.usd_equiv.max);
          let localLiquidityData = Object.entries(limits.assets)
            .filter(
              ([key, value]) =>
                coins.includes(key) ||
                value.max * value.price >= coinToConvertMinValue
            ) // more than 10 dollars constraints for non-specified coins
            .reduce((acc, [key, value]) => {
              acc[key] = { ...value, quantity: '' };
              return acc;
            }, {});
          if (
            !localLiquidityData ||
            Object.keys(localLiquidityData).length === 0 ||
            Object.values(localLiquidityData).every(item => item.max <= 0)
          ) {
            localLiquidityData = coins.reduce((acc, coin) => {
              acc[coin] = { max: 0, quantity: '', price: 0 };
              return acc;
            }, {});
            setDisableModify(true);
          }
          let initLiquidityData = initMaxValues(localLiquidityData);
          setCoinsAmount(initLiquidityData[0]);
          setMaxValue(sumEquivalent(initLiquidityData[0]));
          setOrderedCoins(initLiquidityData[1]);
          setLiquidityData(localLiquidityData);
        }
        setLoaded(true);
      } catch (error) {
        trackEvent('DEV Error', {
          msg: error.message,
          description: 'Error in Portfolio Wallet useEffect',
          data: {
            user_mail: userInfos.email,
            screen_width: window.innerWidth
          }
        });
        console.error(error.message);
      }
    };
    if (!document.title.endsWith(' - Wallet')) {
      // update page title
      document.title = document.title + ' - Wallet';
    }
    if (!loaded) fetchData();
  }, []);

  return exchange === 'virtual' || userInfos.connected_exchanges.length ? (
    <>
      <PricingModal
        showModal={showModalPricing}
        setShowModal={setShowModalPricing}
        whatToDo={
          canStartTrial
            ? 'virtualTrial'
            : userInfos?.pro_tier === 0
            ? 'newSub'
            : 'upgrade'
        }
        newProTier={
          exchange === 'virtual'
            ? 0
            : botID === '8' || sumEquivalent(coinsAmount) > maxCap
            ? 2
            : 0
        }
        virtualAction={{
          action: () => createBot(botParams),
          loading: runBotButtonDisabled
        }}
        showAlertFirstTier={botID === '8' && exchange !== 'virtual'}
      />
      <span
        style={
          window.location.pathname !== '/invest' && showModalPricing
            ? { opacity: 0.2 }
            : {}
        }
      >
        <Row>
          {canStartTrial &&
          exchange !== 'virtual' &&
          liquidityData &&
          getMax() < minCap ? (
            <Alert variant="warning">
              <Row>
                <Col sm={8}>
                  <h5
                    dangerouslySetInnerHTML={{
                      __html: textLang.startTrialTitle[lang]
                    }}
                  />
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: textLang.startTrialText[lang]
                    }}
                  />
                </Col>
                <Col
                  as={Flex}
                  alignItems="center"
                  justifyContent={
                    window.innerWidth > breakpoints['sm'] ? 'end' : 'center'
                  }
                  className="mt-3 mt-sm-0"
                >
                  <Button variant="falcon-success" onClick={goVirtual}>
                    {textLang.startTrialButton[lang]}
                  </Button>
                </Col>
              </Row>
            </Alert>
          ) : (
            liquidityData &&
            getMax() < minCap && (
              <Alert variant="warning">
                <p
                  className="mb-0"
                  dangerouslySetInnerHTML={{
                    __html: textLang.notEnoughAmount[lang] + minCap + '$'
                  }}
                ></p>
              </Alert>
            )
          )}
        </Row>
        <Row className="px-3">
          <Col
            sm={7}
            className={classNames('py-3 mb-2 mb-sm-0 px-3', {
              'd-flex align-items-center': botList.length > 0
            })}
            style={{
              borderRadius: '10px',
              borderWidth: '2px',
              borderStyle: 'solid',
              borderImage: `${bgStyle} 1`
            }}
          >
            <div>
              {!aBotIsPending && (
                <Row className="mb-2">
                  <Col xs={12}>
                    <Flex className="mb-1">
                      <h5 className="mb-0 me-2">{textLang.chooseCoin[lang]}</h5>
                      {exchange === 'virtual' && (
                        <SoftBadge
                          pill
                          bg={'info'}
                          className="d-flex align-items-center"
                        >
                          {textLang.virtualMode[lang]}
                        </SoftBadge>
                      )}
                    </Flex>
                    {liquidityData && (
                      <div>
                        <Form.Check
                          inline
                          type="radio"
                          id="item1Radio"
                          label="Gestione totale"
                          defaultChecked
                          name="inline-radio"
                          onClick={() => setCanModify(false)}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          id="item2Radio"
                          label="Imposta manualmente"
                          name="inline-radio"
                          disabled={disableModify}
                          onClick={() => setCanModify(true)}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              )}
              <Row>
                <Col
                  sm={botList.length === 0 ? 8 : 12}
                  className={`justify-content-${
                    liquidityData ? 'start' : 'center'
                  } align-items-center`}
                  as={Flex}
                >
                  {liquidityData ? (
                    aBotIsPending ? (
                      <div>
                        <h5>{textLang.botPendingTitle[lang]}</h5>
                        <p>{textLang.botPendingText[lang]}</p>
                      </div>
                    ) : (
                      <Row style={{ width: '-webkit-fill-available' }}>
                        <Col
                          xs={12}
                          className="justify-content-center align-items-center ms-1"
                          as={Flex}
                        >
                          <SimpleBarReact
                            style={{
                              maxHeight: `${250}px`,
                              width: '100%'
                            }}
                          >
                            {orderedCoins.map((coin, index) =>
                              (exchange !== 'virtual' ||
                                (exchange === 'virtual' &&
                                  coin === currencyMap[currency])) &&
                              (Number(coinsAmount[coin]?.max.toFixedDown(2)) >
                                0 ||
                                disableModify) ? (
                                <Row
                                  key={index}
                                  className={classNames('', {
                                    'align-items-center': canModify,
                                    'mb-2':
                                      !canModify ||
                                      (canModify &&
                                        (showMore || (!showMore && index < 3)))
                                  })}
                                  style={{ width: '100%' }}
                                >
                                  {canModify ? (
                                    (showMore || (!showMore && index < 3)) && (
                                      <>
                                        <Col
                                          xs={3}
                                          className="d-flex align-items-center justify-content-center flex-row mb-3"
                                        >
                                          {coinsData?.[coin] && (
                                            <Avatar src={coinsData[coin].img} />
                                          )}
                                        </Col>
                                        <Col xs={9} className="ms-0 ps-0">
                                          <h6 className="mb-1 text-secondary">
                                            {`${textLang.amount[lang]} (${
                                              exchange === 'virtual'
                                                ? 'VIRTUAL '
                                                : ''
                                            }${coin}) ${
                                              coinsAmount[coin] &&
                                              coinsAmount[coin].quantity !== 0
                                                ? '- $' +
                                                  coinsAmount[
                                                    coin
                                                  ]?.equivalent.toFixedDown(2)
                                                : ''
                                            }`}
                                          </h6>
                                          <InputGroup className="ms-0 p-1">
                                            <FormControl
                                              aria-label="Amount"
                                              type="number"
                                              onChange={e =>
                                                handleNewAmount(
                                                  coin,
                                                  e.target.value,
                                                  {
                                                    max: liquidityData[coin].max
                                                  }
                                                )
                                              }
                                              value={
                                                coinsAmount[coin]
                                                  ? coinsAmount[coin]
                                                      ?.quantity === ''
                                                    ? ''
                                                    : Number(
                                                        coinsAmount[coin]
                                                          ?.quantity
                                                      )
                                                  : 0
                                              }
                                              min={0}
                                              max={
                                                liquidityData[
                                                  coin
                                                ]?.max?.toFixedDown(
                                                  coinsData?.[coin]
                                                    ?.decimalPrecision || 8
                                                ) || 0
                                              }
                                            />
                                          </InputGroup>
                                          <Form.Range
                                            min={0}
                                            step={
                                              10 **
                                              -(
                                                coinsData?.[coin]
                                                  ?.decimalPrecision || 8
                                              )
                                            }
                                            max={
                                              liquidityData[
                                                coin
                                              ].max.toFixedDown(
                                                coinsData?.[coin]
                                                  ?.decimalPrecision || 8
                                              ) || 0
                                            }
                                            value={
                                              coinsAmount[coin]?.quantity || 0
                                            }
                                            onChange={e =>
                                              handleNewAmount(
                                                coin,
                                                e.target.value,
                                                {
                                                  max: liquidityData[coin].max
                                                }
                                              )
                                            }
                                            style={{
                                              marginLeft: '5px',
                                              width: '97%'
                                            }}
                                            className="amountRange"
                                          />
                                        </Col>
                                      </>
                                    )
                                  ) : (
                                    <>
                                      <Col
                                        xs={8}
                                        as={Flex}
                                        className="mb-1 align-items-center"
                                      >
                                        {coinsData[coin] && (
                                          <Avatar
                                            size="s"
                                            src={coinsData[coin].img}
                                          />
                                        )}
                                        <h6
                                          className="mb-0 text-dark ms-2"
                                          style={{ lineHeight: 'unset' }}
                                        >
                                          {`${coinsData[coin]?.name || coin}${
                                            exchange === 'virtual'
                                              ? ' (VIRTUAL)'
                                              : ''
                                          }:`}
                                        </h6>
                                      </Col>
                                      <Col
                                        as={Flex}
                                        className="mb-1 align-items-center"
                                      >
                                        <h6 className="mb-0 text-dark">
                                          {`${coinsAmount[
                                            coin
                                          ]?.equivalent.toFixedDown(
                                            2
                                          )}${currency}`}
                                        </h6>
                                      </Col>
                                    </>
                                  )}
                                </Row>
                              ) : null
                            )}
                            {!showMore &&
                              canModify &&
                              exchange !== 'virtual' &&
                              orderedCoins.length > 3 && (
                                <Flex
                                  className="justify-content-center"
                                  onClick={() => setShowMore(true)}
                                >
                                  <p className="text-primary pointerCursor">
                                    {textLang.showMore[lang]}
                                    <FontAwesomeIcon
                                      icon="chevron-down"
                                      className="ms-2"
                                    />
                                  </p>
                                </Flex>
                              )}
                          </SimpleBarReact>
                        </Col>
                        <Col xs={12}>
                          {botList.length > 0 && (
                            <>
                              <hr />
                              <h5>
                                {textLang.botsTitle_add[lang]}
                                <InfoPopover
                                  Tag="span"
                                  Text={textLang.infoBots[lang]}
                                  className="ms-1"
                                />
                              </h5>
                              {botList.map((bot, index) => (
                                <Row
                                  key={index}
                                  className="ps-1 mx-1 mb-2 align-items-center"
                                  // onClick={() => handleBotClick(bot.id)}
                                >
                                  <Col
                                    as={Flex}
                                    alignItems="center"
                                    justifyContent="center"
                                    className="px-0"
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      id={`bot-${index}`}
                                      checked={checkedBots.includes(bot.id)}
                                    />
                                    <LottieBotLazy
                                      type={getBotDataById(bot.type).type}
                                      loop={true}
                                      style={{
                                        width:
                                          window.innerWidth < breakpoints['sm']
                                            ? '100%'
                                            : '50%'
                                      }}
                                    />
                                  </Col>
                                  <Col as={Flex} justifyContent="center">
                                    <div style={{ width: 'min-content' }}>
                                      <h5 className="mb-0">{bot.name}</h5>
                                      <small>
                                        {getBotDataById(bot.type).name || ''}
                                      </small>
                                      <div className="mt-2">
                                        {walletImgRender(bot.assets, 'ms-0')}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col className="text-center">
                                    <p className="mt-2 mb-0 fs--1">
                                      {textLang.managed[lang]}
                                    </p>
                                    <p className="mt-2">
                                      <b className={'text-dark'}>
                                        {currency}
                                        {bot.last_stat.eqv[
                                          currencyMap[currency]
                                        ].toFixed(2)}
                                      </b>
                                    </p>
                                  </Col>
                                </Row>
                              ))}
                            </>
                          )}
                        </Col>
                      </Row>
                    )
                  ) : (
                    <div className="text-center">
                      <p className="mt-2">{textLang.waitText[lang]}</p>
                      <Spinner />
                    </div>
                  )}
                </Col>
                {botList.length === 0 && (
                  <Col
                    sm={4}
                    className="d-none d-sm-flex align-items-center justify-content-center flex-column"
                  >
                    <div className="position-relative py-2">
                      <ReactEChartsCore
                        ref={chartRef}
                        echarts={echarts}
                        option={getGraphOption()}
                        style={{
                          height: '9rem',
                          width: '9rem'
                        }}
                      />
                      <div className="position-absolute top-50 start-50 translate-middle text-center">
                        <Avatar
                          icon="robot"
                          iconSize="xl"
                          iconStyle={{ color: '#2c7be5' }}
                        ></Avatar>
                      </div>
                    </div>
                    {loaded && (
                      <h6
                        style={{
                          textAlign: 'center'
                        }}
                      >
                        <b>Tot:</b> ${sumEquivalent(coinsAmount).toFixedDown(2)}
                      </h6>
                    )}
                    {loaded && <p className="text-800 ">{'Min: $' + minCap}</p>}
                  </Col>
                )}
                {exchange !== 'virtual' && !aBotIsPending && (
                  <Row className="mt-4 mb-2 d-none d-sm-flex">
                    <Flex
                      className="ms-4 justify-content-center"
                      onClick={() => setAcceptedTos(!acceptedTos)}
                    >
                      <Form.Check
                        type="checkbox"
                        className="me-2"
                        style={{ cursor: 'pointer' }}
                        name="acceptedTos"
                        checked={acceptedTos}
                        onChange={() => setAcceptedTos(!acceptedTos)}
                      />
                      <Form.Label>
                        <p
                          className="m-0 p-0"
                          dangerouslySetInnerHTML={{
                            __html: textLang.tos[lang]
                          }}
                        ></p>
                      </Form.Label>
                    </Flex>
                  </Row>
                )}
                {showPortfolioError && (
                  <Row className="text-center d-none d-sm-flex">
                    <Col>
                      {portfolioError === 'portfolio_number' ? (
                        <p className="text-danger">
                          {!userInfos.permissions.max_real_portfolio_qty
                            ? textLang.portfolioError[lang]
                            : textLang.portfolioErrorNumber[lang] +
                              userInfos.permissions.max_real_portfolio_qty +
                              ' AI Portfolio.'}
                        </p>
                      ) : portfolioError === 'min_coins' ? (
                        <p className="text-danger">
                          {`${textLang.portfolioErrorMinCoins[lang]} ${coinToConvertMinValue}$: ${minCoinsError}`}
                        </p>
                      ) : (
                        <p className="text-danger">
                          {portfolioError in textLang &&
                          textLang[portfolioError]
                            ? textLang[portfolioError][lang]
                            : textLang.default_error[lang]}
                        </p>
                      )}
                    </Col>
                  </Row>
                )}
                <Col
                  as={Flex}
                  className="justify-content-center d-none d-sm-flex"
                >
                  <IconButton
                    onClick={() => goBack()}
                    variant="outline-secondary"
                    className="me-2 mb-1"
                    icon="arrow-left"
                    iconAlign="right"
                    transform="shrink-3"
                  >
                    {textLang.back[lang]}
                  </IconButton>
                  <IconButton
                    onClick={() => runYourBot()}
                    variant="outline-success"
                    className="me-2 mb-1"
                    disabled={
                      aBotIsPending ||
                      (exchange !== 'virtual' && !acceptedTos) ||
                      Number(sumEquivalent(coinsAmount).toFixedDown(2)) < minCap
                    }
                    icon={runBotButtonDisabled ? '' : 'rocket'}
                    iconAlign="left"
                    transform="shrink-1"
                  >
                    {runBotButtonDisabled && (
                      <Spinner
                        animation="border"
                        role="status"
                        className="me-2"
                        style={{ width: '1rem', height: '1rem' }}
                      ></Spinner>
                    )}
                    {textLang.runBot[lang]}
                  </IconButton>
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            sm={5}
            as={Flex}
            className="align-items-center justify-content-center mt-2 px-0 mb-2 mb-sm-0"
          >
            <div>
              {botList.length > 0 && (
                <Row>
                  <Col className="d-none d-sm-flex align-items-center justify-content-center flex-column">
                    <div className="position-relative py-2">
                      <ReactEChartsCore
                        ref={chartRef}
                        echarts={echarts}
                        option={getGraphOption()}
                        style={{
                          height: '12rem',
                          width: '12rem'
                        }}
                      />
                      <div className="position-absolute top-50 start-50 translate-middle text-center">
                        <Avatar
                          icon="robot"
                          iconSize="xl"
                          iconStyle={{ color: '#2c7be5' }}
                        ></Avatar>
                      </div>
                    </div>
                    {loaded && (
                      <h6
                        style={{
                          textAlign: 'center'
                        }}
                      >
                        <b>Tot:</b> ${sumEquivalent(coinsAmount).toFixedDown(2)}
                      </h6>
                    )}
                    {loaded && <p className="text-800 ">{'Min: $' + minCap}</p>}
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <BotSummary
                    botType={type}
                    assets={coins}
                    exchange={exchange}
                    total={sumEquivalent(coinsAmount).toFixedDown(2)}
                    botName={name}
                    bgStyle={bgStyle}
                    riskLevel={Number(riskUrl)}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          {exchange !== 'virtual' && !aBotIsPending && (
            <Row className="mt-4 mb-2 d-sm-none">
              <Flex
                className="ms-4 justify-content-center"
                onClick={() => setAcceptedTos(!acceptedTos)}
              >
                <Form.Check
                  type="checkbox"
                  className="me-2"
                  style={{ cursor: 'pointer' }}
                  name="acceptedTos"
                  checked={acceptedTos}
                  onChange={() => setAcceptedTos(!acceptedTos)}
                />
                <Form.Label>
                  <p
                    className="m-0 p-0"
                    dangerouslySetInnerHTML={{
                      __html: textLang.tos[lang]
                    }}
                  ></p>
                </Form.Label>
              </Flex>
            </Row>
          )}
          {showPortfolioError && (
            <Row className="text-center d-sm-none">
              <Col>
                {portfolioError === 'portfolio_number' ? (
                  <p className="text-danger">
                    {!userInfos.permissions.max_real_portfolio_qty
                      ? textLang.portfolioError[lang]
                      : textLang.portfolioErrorNumber[lang] +
                        userInfos.permissions.max_real_portfolio_qty +
                        ' AI Portfolio.'}
                  </p>
                ) : (
                  <p className="text-danger">
                    {portfolioError in textLang && textLang[portfolioError]
                      ? textLang[portfolioError][lang]
                      : textLang.default_error[lang]}
                  </p>
                )}
              </Col>
            </Row>
          )}
          <Col as={Flex} className="justify-content-center d-sm-none">
            <IconButton
              onClick={() => goBack()}
              variant="outline-secondary"
              className="me-2 mb-1"
              icon="arrow-left"
              iconAlign="right"
              transform="shrink-3"
            >
              {textLang.back[lang]}
            </IconButton>
            <IconButton
              onClick={() => runYourBot()}
              variant="outline-success"
              className="me-2 mb-1"
              disabled={
                aBotIsPending ||
                (exchange !== 'virtual' && !acceptedTos) ||
                Number(sumEquivalent(coinsAmount).toFixedDown(2)) < minCap
              }
              icon={runBotButtonDisabled ? '' : 'rocket'}
              iconAlign="left"
              transform="shrink-1"
            >
              {runBotButtonDisabled && (
                <Spinner
                  animation="border"
                  role="status"
                  className="me-2"
                  style={{ width: '1rem', height: '1rem' }}
                ></Spinner>
              )}
              {textLang.runBot[lang]}
            </IconButton>
          </Col>
        </Row>
      </span>
    </>
  ) : (
    <NoWalletConnected botID={id} />
  );
};

PortfolioWallet.propTypes = {
  id: PropTypes.string.isRequired,
  setCreatedBot: PropTypes.func.isRequired,
  maxFirstProTierTotalAllocation: PropTypes.number.isRequired
};

export default PortfolioWallet;
